
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<h1 class=" py-2 mb-4">Item Sizes</h1>

			<form @submit.prevent="addItemSizes" autocomplete="off">
				<v-layout row wrap>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="item_sizes.item_size_title" :label="$store.getters.language.data.item_sizes.item_size_title" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="item_sizes.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.item_sizes.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" class="ma-3" append-icon="mdi-magnify" label="search" dense outlined hide-details></v-text-field>

					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="item_size_id">
								<template v-slot:[`item.item_size_id`]="{ item }">
									<div>
										<!-- <v-btn icon :to="'/item_sizes-list/'+item.item_size_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn> -->
										<v-btn color="error" icon class="mx-1" @click="selectItemSizes(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteItemSizesList">{{$store.getters.language.data.item_sizes.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.item_sizes.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.item_sizes.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteItemSizes(selected_item_sizes.item_size_id)">
						{{$store.getters.language.data.item_sizes.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/item_sizes.request.js'
	export default {
		data() {
			return {
				item_sizes: {},
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_item_sizes: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.item_sizes.item_size_title,
						align: 'start',
						sortable: true,
						value: 'item_size_title',
					},
					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'item_size_id',
					}
				],
			}
		},
		computed: {
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			}
		},
		mounted() {
			this.readItemSizes();
			this.item_sizes.user_id = this.user.user_id

		},
		methods: {
			addItemSizes() {
				this.loading_btn = true
				requests.createItemSizes(this.item_sizes).then(r => {
					if (r.status == 200) {
						this.item_sizes = {}
						this.item_sizes.user_id = this.user.user_id
						this.rows.unshift(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'ItemSizes Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add ItemSizes',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteItemSizes(item_size_id) {
				requests.deleteItemSizes(item_size_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.item_size_id != item_size_id
						})
						this.snackbar = {
							value: true,
							text: 'ItemSizes Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteItemSizesList() {
				let ids = this.selected_rows.map(m => m.item_size_id)
				requests.deleteItemSizesList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.item_size_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' ItemSizes Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readItemSizes() {
				this.loading = true
				requests.getAllItemSizes().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ItemSizes',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ItemSizes',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectItemSizes(i) {
				this.selected_item_sizes = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    